<template>
    <v-container  style="margin-top:160px;">


      <v-row fluid>
        <v-col cols="12">
              <v-expansion-panels
        
      >
      <!-- :accordion="accordion"
        :popout="popout"
        :inset="inset"
        :multiple="multiple"
        :focusable="focusable"
        :disabled="disabled"
        :readonly="readonly"
        :flat="flat"
        :hover="hover"
        :tile="tile" -->
       <v-expansion-panel    style="margin-bottom:15px;">
          <v-expansion-panel-header  style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;"><b>Повідомлення iTeam ( {{ global_push.length }} )</b></v-expansion-panel-header>
          <v-expansion-panel-content style="" >
            <v-card style="margin-bottom:15px;" elevation="10" v-for="(content, i) in global_push" :key="i">
            <v-card-title style="justify-content:space-between">
        
           
             <span v-html="content.header"></span><span class="  font-weight-light" style="self-align:flex-end; font-size:17px;">{{content.date}}</span>
              </v-card-title>
                <v-card-text v-html="content.message"></v-card-text>
            </v-card>
                  
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel    style="margin-bottom:15px;">
          <v-expansion-panel-header  style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;"><b>Повідомлення від торгового представника ( {{ pt_push.length }} )</b></v-expansion-panel-header>
          <v-expansion-panel-content style="" >
            <v-card style="margin-bottom:15px;" elevation="10" v-for="(content, i) in pt_push" :key="i">
            <v-card-title style="justify-content:space-between">
        
           
             <span v-html="content.header"></span><span class="  font-weight-light" style="self-align:flex-end; font-size:17px;">{{content.date}}</span>
              </v-card-title>
                <v-card-text v-html="content.message"></v-card-text>
            </v-card>
              
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel  v-if="net_cd" >
          <v-expansion-panel-header  style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;"><b>Повідомлення від власника мережі ( {{ lpr_push.length }} )</b></v-expansion-panel-header>
          <v-expansion-panel-content style="" >
            <v-card style="margin-bottom:15px;" elevation="10" v-for="(content, i) in lpr_push" :key="i">
            <v-card-title style="justify-content:space-between">
        
           
             <span v-html="content.header"></span><span class="  font-weight-light" style="self-align:flex-end; font-size:17px;">{{content.date}}</span>
              </v-card-title>
                <v-card-text v-html="content.message"></v-card-text>
            </v-card>
              
          </v-expansion-panel-content>
        </v-expansion-panel>
              </v-expansion-panels>
      </v-col>
    </v-row>






      
        


    </v-container>
</template>



<script>
import btnrnd from "../components/btnrnd.vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,
  },
  data: () => ({
    push_arr:[],
    net_cd:false
  }),
  computed: {
    global_push_l:function () {

      return this.global_push.length();
    },

    global_push: function () {
      return this.push_arr.filter((i) => i.type == "0");
    },
    pt_push: function () {
      return this.push_arr.filter((i) => i.type == "2");
    },
    lpr_push: function () {
      return this.push_arr.filter((i) => i.type == "1");
    },
    
      title() {
        return this.$t('title')
      },
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {
     axios
        .post(
          API_LOCATION,
          { data: "component_data", action: "push_arch", id: this.task_id },
          { headers: { "content-type": "application/x-www-form-urlencoded" } }
        )
        .then((resp) => {
          
          this.push_arr=resp.data;
          console.log(this.news);
        });

      axios
        .post(
          API_LOCATION,
          { data: "component_data", action: "is_net_cd", id: this.task_id },
          { headers: { "content-type": "application/x-www-form-urlencoded" } }
        )
        .then((resp) => {
          
          if(resp.data==1)
          {
            this.net_cd=true;
          }
          console.log(this.news);
        });  
  },
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
  },
};
</script>


<i18n>
{
  
  "ua":{
      "title":"Архів сповіщень"


      

  }
}
</i18n>